import React, { useState, useEffect } from "react";

const SpotifyChecker: React.FC = () => {
  const [playlistUrl, setPlaylistUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [results] = useState<{ artist: string; song: string }[]>([]);
  const [authCode, setAuthCode] = useState<string | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    // Перевіряємо, чи є код у URL або в localStorage
    const storedCode = localStorage.getItem("spotifyAuthCode");

    if (code && !storedCode) {
      // Якщо отримали новий код і його ще немає в localStorage
      localStorage.setItem("spotifyAuthCode", code);
      setAuthCode(code);

      // Прибираємо код із URL після збереження
      window.history.replaceState({}, document.title, "/spoty");
    } else if (storedCode) {
      // Якщо код вже є в localStorage, використовуємо його
      setAuthCode(storedCode);
    } else {
      // Якщо коду немає ні в URL, ні в localStorage — викликаємо авторизацію
      redirectToSpotify();
    }
  }, []);

  const redirectToSpotify = () => {
    const clientId = "060a211492244a119eb769511b4d62bc"; // Ваш Client ID
    const redirectUri = encodeURIComponent("http://localhost:3000/spoty");
    const scope = "playlist-read-private";
    const authUrl = `https://accounts.spotify.com/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;

    // Перенаправляємо користувача на Spotify для авторизації
    window.location.href = authUrl;
  };

  const handleCheck = async () => {
    if (!authCode) {
      console.error("Authorization code is missing!");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `http://localhost:8080/api/validate-playlist?code=${authCode}`, // Передаємо код авторизації
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ url: playlistUrl }), // Передаємо URL плейлиста
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data); // Обробка отриманих даних
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-xl font-bold mb-4">Перевірка плейлиста на артистів</h1>
      <input
        type="text"
        value={playlistUrl}
        onChange={(e) => setPlaylistUrl(e.target.value)}
        placeholder="Вставте посилання на плейлист"
        className="border p-2 mb-4 w-full"
      />
      <button
        onClick={handleCheck}
        className="bg-blue-500 text-white p-2 rounded disabled:opacity-50"
        disabled={!playlistUrl || loading}
      >
        {loading ? (
          <svg
            className="animate-spin h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
            />
          </svg>
        ) : (
          "Перевірити"
        )}
      </button>
      {results.length > 0 && (
        <div className="mt-4">
          <h2 className="text-lg font-bold mb-2">Російські артисти у плейлисті:</h2>
          <ul>
            {results.map((track, index) => (
              <li key={index} className="mb-2">
                {track.artist} - {track.song}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SpotifyChecker;
