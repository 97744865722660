import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  KeyboardEvent,
} from "react";
import { motion, AnimatePresence } from "framer-motion";

// Import config for key-to-sound mapping
import soundConfig from "../assets/sounds_keytyping/nk-cream/config.json";

// Define message structure
interface Message {
  id: number;
  text: string;
  timestamp: number;
  isTyping: boolean;
}

const ChatPage: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [soundEnabled, setSoundEnabled] = useState<boolean>(true);
  const [volume] = useState<number>(1.0); // Volume between 0.0 and 1.0
  const messageIdRef = useRef(0);
  const currentMessageRef = useRef<string>(""); // Store current message
  const isPageActive = useRef<boolean>(document.visibilityState === "visible");
  const currentTypingMessageIdRef = useRef<number | null>(null);

  // Function to load and play sound
  const playSound = useCallback(
    (key: string) => {
      if (soundEnabled) {
        const lowercaseKey = key.toLowerCase(); // Ensure key is always in lowercase
        const soundFile =
          soundConfig.defines[lowercaseKey as keyof typeof soundConfig.defines]; // Get the sound file for the key
        if (soundFile) {
          const audio = new Audio(
            require(`../assets/sounds_keytyping/nk-cream/${soundFile}`),
          );
          audio.volume = volume; // Set the volume
          audio.play();
        } else {
          console.warn(`No sound found for key: ${lowercaseKey}`);
        }
      }
    },
    [soundEnabled, volume],
  );

  // Function for typing messages
  const updateTypingMessage = useCallback(() => {
    setMessages((prevMessages) => {
      return prevMessages.map((msg) =>
        msg.isTyping && msg.id === currentTypingMessageIdRef.current
          ? { ...msg, text: currentMessageRef.current }
          : msg,
      );
    });
  }, []);

  // Function to start typing a message
  const startTypingMessage = useCallback(() => {
    if (currentTypingMessageIdRef.current === null) {
      const newMessage: Message = {
        id: messageIdRef.current++,
        text: currentMessageRef.current,
        timestamp: Date.now(),
        isTyping: true,
      };
      currentTypingMessageIdRef.current = newMessage.id;
      setMessages((prevMessages) => [newMessage, ...prevMessages]);
    } else {
      updateTypingMessage();
    }
  }, [updateTypingMessage]);

  // Function to finalize a message
  const finalizeMessage = useCallback(() => {
    if (currentTypingMessageIdRef.current === null) return;

    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === currentTypingMessageIdRef.current
          ? { ...msg, isTyping: false }
          : msg,
      ),
    );

    currentTypingMessageIdRef.current = null;
    currentMessageRef.current = "";

    setTimeout(() => {
      setMessages((prevMessages) =>
        prevMessages.filter((msg, index) => index !== prevMessages.length - 1),
      );
    }, 5000);
  }, []);

  useEffect(() => {
    document.title = "Bubble Text Writer";
    const handleVisibilityChange = () => {
      isPageActive.current = document.visibilityState === "visible";
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isPageActive.current) return;
      if (e.ctrlKey || e.metaKey || e.altKey) return;

      // Handle key press
      const key = e.key.toLowerCase();

      if (key === "enter") {
        e.preventDefault();
        if (currentMessageRef.current.trim() !== "") {
          finalizeMessage();
          playSound("28"); // Play enter sound
        }
        return;
      }

      if (key === "backspace") {
        e.preventDefault();
        currentMessageRef.current = currentMessageRef.current.slice(0, -1);
        startTypingMessage();
        playSound("14"); // Play backspace sound
        return;
      }

      if (key === " ") {
        // Check for space key
        e.preventDefault();
        currentMessageRef.current += " "; // Add a space to the current message
        startTypingMessage();
        playSound("space"); // Use 'space' to look up the sound
        return;
      }

      if (key === "shift") {
        // Check for Shift key
        e.preventDefault();
        playSound("14"); // Play sound associated with Shift
        return;
      }

      // Add logic to prevent repeated sound on holding the key
      if (key.length === 1) {
        e.preventDefault();
        currentMessageRef.current += e.key;
        startTypingMessage();
        playSound(key); // Play the sound associated with the key
      }
    };

    window.addEventListener("keydown", handleKeyDown as any);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("keydown", handleKeyDown as any);
    };
  }, [finalizeMessage, playSound, startTypingMessage]);

  // Function to dynamically calculate bubble width
  const getBubbleWidth = (text: string) => {
    const baseWidth = 50;
    const charWidth = 10;
    const maxWidth = 320;
    const calculatedWidth = Math.min(
      baseWidth + text.length * charWidth,
      maxWidth,
    );
    return `${calculatedWidth}px`;
  };

  return (
    <div className="min-h-screen bg-green-500 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md p-4 relative">
        <div>
          <a
            href="/"
            className="fixed top-4 left-4 px-4 py-2 text-lg font-semibold text-white bg-blue-500 hover:bg-blue-600 rounded-lg"
          >
            Home
          </a>
        </div>
        {/* Sound toggle button */}
        <button
          className="fixed top-4 right-4 px-4 py-2 text-lg font-semibold text-white bg-red-500 hover:bg-red-600 rounded-lg"
          onClick={() => setSoundEnabled(!soundEnabled)}
        >
          {soundEnabled ? "Disable Sound" : "Enable Sound"}
        </button>

        <div className="fixed bottom-4 left-4 w-full max-w-md space-y-2 overflow-y-auto max-h-96 flex flex-col-reverse space-y-reverse">
          <AnimatePresence>
            {messages.map((msg, index) => (
              <motion.div
                key={msg.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3 }}
                style={{ width: getBubbleWidth(msg.text) }}
                className={`flex flex-col leading-1.5 p-4 border border-gray-200 bg-gray-100 dark:bg-gray-700 rounded-e-2xl rounded-es-2xl break-words shadow-md ${
                  index === 0 ? "mb-6" : ""
                }`}
              >
                <span>{msg.text}</span>
                {msg.isTyping && (
                  <motion.span
                    className="ml-0.5 mt-1"
                    animate={{ opacity: [0, 1, 0] }}
                    transition={{ repeat: Infinity, duration: 1 }}
                  />
                )}
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
