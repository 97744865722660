import { useState, useEffect } from "react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const navigation = [
    { name: "Home", href: "/" },
    { name: "Project", href: "/project" },
    { name: "Discord", href: "https://discord.gg/scuttle" },
    { name: "Blog", href: "/bllog" },
    // { name: "Discord", href: "https://scuttle.eeaq.xyz" },
];

interface Post {
    content: string;
    timestamp: string;
    images?: string[];
}

interface ProfileData {
    avatar: string;
    username: string;
    posts: Post[];
}

export default function BlogPage() {
    const [profileData, setProfileData] = useState<ProfileData | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isDarkMode, setDarkMode] = useState(false);
    const [showImage, setShowImage] = useState<string | null>(null); // Додано для показу зображення

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch("/api/threads");
                if (!response.ok) throw new Error('Network response was not ok');

                const data: ProfileData = await response.json();
                console.log(data); // Log the data for debugging
                setProfileData(data);
            } catch (error) {
                console.error("Error fetching profile data:", error);
                setError("Failed to fetch profile data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };


        fetchData();
    }, []);

    // Функція для перемикання стану (показати всі пости або лише один)
    const { t } = useTranslation();

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [isDarkMode]);

    useEffect(() => {
        document.title = t("Blog"); // Використовуємо t для перекладу назви сторінки
    }, [t]);

    const toggleDarkMode = (checked: boolean) => {
        setDarkMode(checked);
    };

    const handleLanguageChange = () => {
        // Implement your language switching logic here
        const newLang = i18n.language === "uk" ? "en" : "uk"; // Switch between Ukrainian and English
        i18n.changeLanguage(newLang);
    };

    const handleImageClick = (image: string) => {
        setShowImage(image); // Встановлюємо зображення для показу
    };

    const handleCloseImage = () => {
        setShowImage(null); // Закриваємо зображення
    };

    return (
        <div className="bg-orange-50 dark:bg-gray-900 animate-fadeIn duration-1000 min-h-screen w-full">
            <header className="absolute inset-x-0 top-0 z-50">
                <nav
                    aria-label="Global"
                    className="flex items-center justify-between p-6 lg:px-8"
                >
                    <div className="flex lg:flex-1"></div>
                    <div className="flex lg:hidden">
                        {!mobileMenuOpen && (
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(true)}
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 dark:text-gray-300"
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        )}
                    </div>

                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-gray-900 dark:text-gray-300 hover:bg-orange-400 rounded-lg focus:ring-4 focus:outline-none focus:ring-orange-300 dark:hover:bg-orange-700 dark:focus:ring-orange-800"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <button
                            onClick={handleLanguageChange}
                            style={{ cursor: "pointer", border: "none", background: "none" }}
                        >
                            <svg className="mr-4 fill-gray-900 dark:fill-white" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256"><path d="M128,24h0A104,104,0,1,0,232,128,104.12,104.12,0,0,0,128,24Zm88,104a87.61,87.61,0,0,1-3.33,24H174.16a157.44,157.44,0,0,0,0-48h38.51A87.61,87.61,0,0,1,216,128ZM102,168H154a115.11,115.11,0,0,1-26,45A115.27,115.27,0,0,1,102,168Zm-3.9-16a140.84,140.84,0,0,1,0-48h59.88a140.84,140.84,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.84a157.44,157.44,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154,88H102a115.11,115.11,0,0,1,26-45A115.27,115.27,0,0,1,154,88Zm52.33,0H170.71a135.28,135.28,0,0,0-22.3-45.6A88.29,88.29,0,0,1,206.37,88ZM107.59,42.4A135.28,135.28,0,0,0,85.29,88H49.63A88.29,88.29,0,0,1,107.59,42.4ZM49.63,168H85.29a135.28,135.28,0,0,0,22.3,45.6A88.29,88.29,0,0,1,49.63,168Zm98.78,45.6a135.28,135.28,0,0,0,22.3-45.6h35.66A88.29,88.29,0,0,1,148.41,213.6Z"></path></svg>
                        </button>
                        <DarkModeSwitch
                            className="mx-3 block leading-7"
                            checked={isDarkMode}
                            onChange={toggleDarkMode}
                            size={20}
                        />
                    </div>
                </nav>

                {/* Мобільне меню */}
                {mobileMenuOpen && (
                    <div className="lg:hidden fixed inset-0 z-50 bg-gray-900 bg-opacity-75 animate-fadeIn duration-20">
                        <div className="flex items-center justify-end p-4">
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(false)}
                                className="text-gray-200 hover:text-white"
                            >
                                <span className="sr-only">Close menu</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18 18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-4 py-6 space-y-4 sm:px-6">
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className="block text-base font-medium text-gray-100 hover:text-white"
                                    onClick={() => setMobileMenuOpen(false)} // Закрити меню після кліку
                                >
                                    {item.name}
                                </a>
                            ))}
                            {/* Додати перемикач теми в мобільному меню */}
                            <div className="mt-3 pt-5 flex justify-left">
                                <button
                                    onClick={handleLanguageChange}
                                    style={{
                                        cursor: "pointer",
                                        border: "none",
                                        background: "none",
                                    }}
                                >
                                    <svg className="mr-4 fill-gray-900 dark:fill-white" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256"><path d="M128,24h0A104,104,0,1,0,232,128,104.12,104.12,0,0,0,128,24Zm88,104a87.61,87.61,0,0,1-3.33,24H174.16a157.44,157.44,0,0,0,0-48h38.51A87.61,87.61,0,0,1,216,128ZM102,168H154a115.11,115.11,0,0,1-26,45A115.27,115.27,0,0,1,102,168Zm-3.9-16a140.84,140.84,0,0,1,0-48h59.88a140.84,140.84,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.84a157.44,157.44,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154,88H102a115.11,115.11,0,0,1,26-45A115.27,115.27,0,0,1,154,88Zm52.33,0H170.71a135.28,135.28,0,0,0-22.3-45.6A88.29,88.29,0,0,1,206.37,88ZM107.59,42.4A135.28,135.28,0,0,0,85.29,88H49.63A88.29,88.29,0,0,1,107.59,42.4ZM49.63,168H85.29a135.28,135.28,0,0,0,22.3,45.6A88.29,88.29,0,0,1,49.63,168Zm98.78,45.6a135.28,135.28,0,0,0,22.3-45.6h35.66A88.29,88.29,0,0,1,148.41,213.6Z"></path></svg>
                                </button>
                                <DarkModeSwitch
                                    className="block"
                                    checked={isDarkMode}
                                    onChange={toggleDarkMode}
                                    size={20}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </header>

            <div className="mx-auto max-w-screen-md px-4 sm:px-6 lg:px-8 py-32 sm:py-48 lg:py-36">
                <div className="text-center">
                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-6xl">
                        {t("blog.hero.title")}
                    </h1>
                </div>
                <div className="mx-auto max-w-2xl pt-20 sm:pt-25 lg:pt-25">
                    <div className="mx-auto max-w-2xl pt-20 sm:pt-25 lg:pt-25">
                        <div className="max-w-2xl p-6 text-left bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-lg">
                            {loading && <div className="text-gray-500">Loading...</div>}
                            {error && <div className="text-red-500">{error}</div>}
                            {profileData && profileData.posts && profileData.posts.length > 0 && (
                                <div>
                                    {profileData.posts.map((post, index) => (
                                        <div key={index} className="mb-6">
                                            <div className="flex items-start mb-2">
                                                {profileData.avatar && (
                                                    <img
                                                        src={profileData.avatar}
                                                        alt="Profile Avatar"
                                                        className="w-9 h-9 rounded-full mr-4"
                                                    />
                                                )}
                                                <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                                                    {profileData.username}
                                                </h2>
                                            </div>
                                            <p className="text-gray-800 dark:text-gray-300 mb-2">
                                                {post.content || "No content available"}
                                            </p>
                                            {post.images && post.images.length > 0 && (
                                                <div className="flex flex-wrap">
                                                    {post.images.map((image, imgIndex) => (
                                                        <img
                                                            key={imgIndex}
                                                            src={image}
                                                            alt=""
                                                            className="w-[198px] h-[auto] rounded-lg cursor-pointer mr-2 mb-2"
                                                            sizes="(max-width: 222px) calc(100vw - 24px), 198px"
                                                            onClick={() => handleImageClick(image)} // Додаємо обробник кліку
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                            {post.timestamp && (
                                                <span className="text-gray-500 dark:text-gray-400 text-sm mt-6">
                                                    {new Date(post.timestamp).toLocaleString()}
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Модальне вікно для зображення */}
                    {showImage && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
                            onClick={handleCloseImage} // Закриваємо при натисканні
                        >
                            <img src={showImage} alt="Full size" className="max-w-full max-h-full" />
                        </div>
                    )}
                </div>
            </div >
        </div>
    );
}
