import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation(); // Ensure you get the i18n instance

  const handleLanguageChange = (lng: string) => {
    // Check if i18n is defined
    if (i18n) {
      i18n.changeLanguage(lng)
        .then(() => {
          console.log(`Language changed to ${lng}`);
        })
        .catch((error) => {
          console.error('Failed to change language:', error);
        });
    } else {
      console.error('i18n instance is not available');
    }
  };

  return (
    <div>
      <button onClick={() => handleLanguageChange('en')}>English</button>
      <button onClick={() => handleLanguageChange('uk')}>Ukrainian</button>
    </div>
  );
};

export default LanguageSwitcher;
