import React, { useState, useEffect } from 'react';

const PomodoroTimerPage: React.FC = () => {
  const [minutes, setMinutes] = useState<number>(25); // Поточні хвилини
  const [seconds, setSeconds] = useState<number>(0); // Поточні секунди
  const [isActive, setIsActive] = useState<boolean>(false); // Чи таймер активний
  const [initialMinutes, setInitialMinutes] = useState<number>(25); // Для зберігання обраного користувачем значення
  const [newMinutes, setNewMinutes] = useState<string>(''); // Значення для нового таймера перед підтвердженням

  useEffect(() => {
    document.title = "Pomodoro Timer";
    let interval: NodeJS.Timeout | null = null;

    if (isActive && (minutes > 0 || seconds > 0)) {
      interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds((prevSeconds) => prevSeconds - 1);
        } else if (seconds === 0 && minutes > 0) {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59); // Переводимо секунди до 59, коли хвилини зменшились
        }
      }, 1000);
    } else if (!isActive && interval !== null) {
      clearInterval(interval);
    }

    return () => {
      if (interval !== null) {
        clearInterval(interval);
      }
    };
  }, [isActive, minutes, seconds]);

  // Логіка запуску і паузи таймера
  const handleStart = () => {
    setIsActive(!isActive);
  };

  // Скидання таймера до початкового значення
  const handleReset = () => {
    setIsActive(false);
    setMinutes(initialMinutes);
    setSeconds(0);
  };

  // Обробка зміни часу, але не змінює таймер до підтвердження
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMinutes(e.target.value);
  };

  // Підтвердження вибору користувачем нового часу
  const handleConfirmTimeChange = () => {
    const newTime = parseInt(newMinutes);
    if (!isNaN(newTime) && newTime > 0) {
      setMinutes(newTime);
      setInitialMinutes(newTime);
      setSeconds(0);
      setNewMinutes('');
    }
  };

  // Форматування хвилин і секунд (додаємо 0 спереду, якщо потрібно)
  const formatTime = (num: number): string => {
    return num.toString().padStart(2, '0');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 className="text-4xl font-bold mb-8">Pomodoro Timer</h1>
      <div className="text-6xl font-mono mb-8">
        {formatTime(minutes)}:{formatTime(seconds)}
      </div>

      <div className="flex flex-col items-center mb-4">
        <label htmlFor="time-input" className="mb-2 text-lg font-semibold">
          Змінити час (хвилини):
        </label>
        <input
          type="number"
          id="time-input"
          value={newMinutes}
          onChange={handleTimeChange}
          disabled={isActive} // Не можна змінювати час, коли таймер запущений
          className="w-20 p-2 text-lg text-center border rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={handleConfirmTimeChange}
          disabled={isActive || !newMinutes} // Кнопка неактивна, якщо таймер запущений або не введене значення
          className="px-4 py-2 text-lg font-semibold text-white bg-blue-500 hover:bg-blue-600 rounded-lg"
        >
          Підтвердити
        </button>
      </div>

      <div className="flex space-x-4">
        <button
          onClick={handleStart}
          className={`px-4 py-2 text-lg font-semibold text-white rounded-lg ${
            isActive ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
          }`}
        >
          {isActive ? 'Зупинити' : 'Розпочати'}
        </button>
        <button
          onClick={handleReset}
          className="px-4 py-2 text-lg font-semibold text-white bg-gray-500 hover:bg-gray-600 rounded-lg"
        >
          Скинути
        </button>
      </div>
    </div>
  );
};

export default PomodoroTimerPage;
