import { useState, useEffect } from 'react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { DarkModeSwitch } from 'react-toggle-dark-mode';

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

export default function ScuttleDiscord() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [isDarkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  useEffect(() => {
    document.title = 'scuttle Discord Server'; // Змінюємо назву сторінки
  }, []); // Порожній масив залежностей означає, що цей ефект виконається тільки після монтування компонента


  const toggleDarkMode = (checked: boolean) => {
    setDarkMode(checked);
  };

  return (
    <div className="bg-cyan-50 dark:bg-gray-900">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">scuttle</span>
              <img
                alt=""
                src="https://imgur.com/iO3QHs7.png?color=indigo&shade=600"
                className="h-8 w-auto"
              />
            </a>
          </div>
          <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 dark:text-gray-300"
            >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-300">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <DarkModeSwitch
              className="mx-3 block leading-7"
              checked={isDarkMode}
              onChange={toggleDarkMode}
              size={20}
            />
            <a href="/" className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-300">
              Log in
            </a>
          </div>
        </nav>
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        {/* Інший контент */}
      </div>
      <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-6xl">
            Україномовний сервер scuttle
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
            Чілова атмосфера роботяг після роботи. Заходиш, сидиш, пиздиш про життя, чим тобі не казка?
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="https://discord.gg/scuttle"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Доєднатись
            </a>
            <a href="/" className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
              Дізнатись більше <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-30">
            <div className="text-center">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                
              </h1>
            </div>
        </div>
      </div>
    </div>
  )
}
