import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainPage from "./components/MainPage";
import ErrorPages from "./components/404";
import HeaderPagesTested from "./components/headertest";
import ScuttleDiscord from "./components/ScuttleDiscord";
import SpotifyChecker from "./components/SpotyCheck";
import PomodoroTimerPage from "./components/PomodoroTimerPage";
import GreenScreenBubble from "./components/GreenScreenBubble";
import AllProjectPage from "./components/ProjectPage";
import LanguageSwitcher from "./components/checkTranslate";
import SupportPage from "./components/SuportPage";
import BlogPage from "./components/BlogPages"

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/header" element={<HeaderPagesTested />} />
        <Route path="/scuttle" element={<ScuttleDiscord />} />
        <Route path="/spoty" element={<SpotifyChecker />} />
        <Route path="/pomodoro" element={<PomodoroTimerPage />} />
        <Route path="/bubble" element={<GreenScreenBubble />} />
        <Route path="/project" element={<AllProjectPage />} />
        <Route path="/translate" element={<LanguageSwitcher />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/blog" element={<BlogPage/>} />

        {/* 404 page */}
        <Route path="*" element={<ErrorPages />} />
      </Routes>
    </Router>
  );
};

export default App;
