import { useState, useEffect } from "react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const navigation = [
  { name: "Home", href: "/" },
  { name: "Project", href: "/project" },
  { name: "Discord", href: "https://discord.gg/scuttle" },
  { name: "Blog", href: "/blog" },
  // { name: "Discord", href: "https://scuttle.eeaq.xyz" },
];

export default function MainPage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDarkMode, setDarkMode] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [isDarkMode]);

  useEffect(() => {
    document.title = "eeaq"; // Змінюємо назву сторінки на потрібну
  }, [t]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js";
    script.type = "module";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const toggleDarkMode = (checked: boolean) => {
    setDarkMode(checked);
  };

  const handleLanguageChange = () => {
    // Implement your language switching logic here
    const newLang = i18n.language === "uk" ? "en" : "uk"; // Switch between Ukrainian and English
    i18n.changeLanguage(newLang);
  };

  const posts = [
    {
      id: 1,
      title: "Html/Css",
      description: t("main.stakinfo.html"),
      date: "Jul 25, 2017",
      datetime: "2017-07-25",
      category: { title: "Web Development" },
    },
    {
      id: 2,
      title: "JavaScript",
      description: t("main.stakinfo.js"),
      date: "Jun 01, 2019",
      datetime: "2019-06-01",
      category: { title: "Development" },
    },
    {
      id: 3,
      title: "Python",
      description: t("main.stakinfo.py"),
      date: "Nov 18, 2022",
      datetime: "2022-11-18",
      category: { title: "Bot Development" },
    },
    {
      id: 4,
      title: "Figma",
      description: t("main.stakinfo.figma"),
      date: "Sep 10, 2017",
      datetime: "2017-09-10",
      category: { title: "Design" },
    },
    {
      id: 5,
      title: "MongoDB",
      description: t("main.stakinfo.mdb"),
      date: "Feb 19, 2023",
      datetime: "2023-02-19",
      category: { title: "DataBase" },
    },
    {
      id: 6,
      title: "React",
      description: t("main.stakinfo.react"),
      date: "At the moment",
      datetime: "2024 - present",
      category: { title: "Development" },
    },
    // More posts...
  ];

  return (
    <div className="bg-orange-50 dark:bg-gray-900 animate-fadeIn duration-1000 min-h-screen w-ful">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          aria-label="Global"
          className="flex items-center justify-between p-6 lg:px-8"
        >
          <div className="flex lg:flex-1"></div>
          <div className="flex lg:hidden">
            {!mobileMenuOpen && (
              <button
                type="button"
                onClick={() => setMobileMenuOpen(true)}
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-orange-700 dark:text-gray-300"
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon aria-hidden="true" className="h-6 w-6" />
              </button>
            )}
          </div>

          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-gray-900 dark:text-gray-300 hover:bg-orange-400 rounded-lg focus:ring-4 focus:outline-none focus:ring-orange-300 dark:hover:bg-orange-700 dark:focus:ring-orange-800"
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <button
              onClick={handleLanguageChange}
              style={{ cursor: "pointer", border: "none", background: "none" }}
            >
              <svg className="mr-4 fill-gray-900 dark:fill-white" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256"><path d="M128,24h0A104,104,0,1,0,232,128,104.12,104.12,0,0,0,128,24Zm88,104a87.61,87.61,0,0,1-3.33,24H174.16a157.44,157.44,0,0,0,0-48h38.51A87.61,87.61,0,0,1,216,128ZM102,168H154a115.11,115.11,0,0,1-26,45A115.27,115.27,0,0,1,102,168Zm-3.9-16a140.84,140.84,0,0,1,0-48h59.88a140.84,140.84,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.84a157.44,157.44,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154,88H102a115.11,115.11,0,0,1,26-45A115.27,115.27,0,0,1,154,88Zm52.33,0H170.71a135.28,135.28,0,0,0-22.3-45.6A88.29,88.29,0,0,1,206.37,88ZM107.59,42.4A135.28,135.28,0,0,0,85.29,88H49.63A88.29,88.29,0,0,1,107.59,42.4ZM49.63,168H85.29a135.28,135.28,0,0,0,22.3,45.6A88.29,88.29,0,0,1,49.63,168Zm98.78,45.6a135.28,135.28,0,0,0,22.3-45.6h35.66A88.29,88.29,0,0,1,148.41,213.6Z"></path></svg>
            </button>
            <DarkModeSwitch
              className="mx-3 block leading-7"
              checked={isDarkMode}
              onChange={toggleDarkMode}
              size={20}
            />
          </div>
        </nav>

        {/* Мобільне меню */}
        {mobileMenuOpen && (
          <div className="lg:hidden fixed inset-0 z-50 bg-gray-900 bg-opacity-75 animate-fadeIn duration-20">
            <div className="flex items-center justify-end p-4">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="text-gray-200 hover:text-white"
              >
                <span className="sr-only">Close menu</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="px-4 py-6 space-y-4 sm:px-6">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="block text-base font-medium text-gray-100 hover:text-white"
                  onClick={() => setMobileMenuOpen(false)} // Закрити меню після кліку
                >
                  {item.name}
                </a>
              ))}
              {/* Додати перемикач теми в мобільному меню */}
              <div className="mt-3 pt-5 flex justify-left">
                <button
                  onClick={handleLanguageChange}
                  style={{
                    cursor: "pointer",
                    border: "none",
                    background: "none",
                  }}
                >
                  <svg className="mr-4 fill-gray-900 dark:fill-white" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256"><path d="M128,24h0A104,104,0,1,0,232,128,104.12,104.12,0,0,0,128,24Zm88,104a87.61,87.61,0,0,1-3.33,24H174.16a157.44,157.44,0,0,0,0-48h38.51A87.61,87.61,0,0,1,216,128ZM102,168H154a115.11,115.11,0,0,1-26,45A115.27,115.27,0,0,1,102,168Zm-3.9-16a140.84,140.84,0,0,1,0-48h59.88a140.84,140.84,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.84a157.44,157.44,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154,88H102a115.11,115.11,0,0,1,26-45A115.27,115.27,0,0,1,154,88Zm52.33,0H170.71a135.28,135.28,0,0,0-22.3-45.6A88.29,88.29,0,0,1,206.37,88ZM107.59,42.4A135.28,135.28,0,0,0,85.29,88H49.63A88.29,88.29,0,0,1,107.59,42.4ZM49.63,168H85.29a135.28,135.28,0,0,0,22.3,45.6A88.29,88.29,0,0,1,49.63,168Zm98.78,45.6a135.28,135.28,0,0,0,22.3-45.6h35.66A88.29,88.29,0,0,1,148.41,213.6Z"></path></svg>
                </button>
                <DarkModeSwitch
                  className="block"
                  checked={isDarkMode}
                  onChange={toggleDarkMode}
                  size={20}
                />
              </div>
            </div>
          </div>
        )}
      </header>

      <div className="mx-auto max-w-screen-md px-4 sm:px-6 lg:px-8 py-32 sm:py-48 lg:py-36 scroll-smooth">
        <div className="text-center">
          <model-viewer
            src="/models/low_poly_crt.glb"
            alt="A 3D model"
            auto-rotate
            camera-controls
            style={{ width: "100%", height: "500px" }}
          ></model-viewer>
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-6xl">
            eeaq
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
            {t("main.hero.description")}
          </p>
          <div className="mt-10 flex flex-wrap justify-center gap-x-6 gap-y-4">
            <a
              href="#social-block"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 duration-20 scroll-smooth"
            >
              {t("main.hero.buttonConnect")}
            </a>
            <a
              href="/support"
              className="flex items-center rounded-md border bg-red-400 px-3.5 py-2.5 text-sm transition-all duration-20 shadow-sm hover:shadow-lg text-white hover:text-white hover:bg-red-500 focus:text-white focus:bg-slate-800 focus:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            >
              {t("main.hero.buttonSupport")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#ffebeb"
                viewBox="0 0 256 256"
                className="ms-2"
              >
                <path d="M178,40c-20.65,0-38.73,8.88-50,23.89C116.73,48.88,98.65,40,78,40a62.07,62.07,0,0,0-62,62c0,70,103.79,126.66,108.21,129a8,8,0,0,0,7.58,0C136.21,228.66,240,172,240,102A62.07,62.07,0,0,0,178,40ZM128,214.8C109.74,204.16,32,155.69,32,102A46.06,46.06,0,0,1,78,56c19.45,0,35.78,10.36,42.6,27a8,8,0,0,0,14.8,0c6.82-16.67,23.15-27,42.6-27a46.06,46.06,0,0,1,46,46C224,155.61,146.24,204.15,128,214.8Z"></path>
              </svg>
            </a>
          </div>
        </div>
        <div className="mx-auto max-w-2xl pt-20 sm:pt-25 lg:pt-25">
          <div className="text-center">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
              {t("main.about.title")}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
              {t("main.about.descriptionFirst")}{" "}
              <a href="/scuttle" className="text-teal-600 non-decoration">
                scuttle
              </a>
              {t("main.about.descriptionSecond")}{" "}
              <a href="/" className="text-teal-600 non-decoration">
                milhub
              </a>
              {t("main.about.descriptionThird")}
            </p>
          </div>
        </div>
        <div className="mx-auto max-w-screen-md pt-20 sm:pt-25 lg:pt-25">
          <div className="text-center">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
              {t("main.stack.title")}
            </h1>
            <div className="mx-auto mt-6 grid grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-3 text-gray-700 dark:text-gray-300">
              {posts.map((post) => (
                <article
                  key={post.id}
                  className="flex max-w-xl flex-col items-start justify-between"
                >
                  <div className="flex items-center gap-x-4 text-xs">
                    <time dateTime={post.datetime} className="">
                      {post.date}
                    </time>
                    <p className="relative z-10 rounded-full bg-gray-300 dark:bg-gray-700 px-3 py-1.5 font-medium">
                      {post.category.title}
                    </p>
                  </div>
                  <div className="group relative">
                    <h3 className="mt-3 text-lg font-semibold leading-6">
                      <p>
                        <span className="absolute inset-0" />
                        {post.title}
                      </p>
                    </h3>
                    <p className="mt-5 line-clamp-3 text-sm">
                      {post.description}
                    </p>
                  </div>
                </article>
              ))}
            </div>
          </div>
        </div>
        <div id="social-block" className="mx-auto max-w-screen-md pt-20 sm:pt-25 lg:pt-25">
          <div className="text-center">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
              {t("main.social.title")}
            </h1>
            <div
              id="button"
              className="mt-10 flex flex-wrap justify-center gap-x-6 gap-y-4"
            >
              <a
                href="/telegram"
                className="flex items-center rounded-md border border-slate-300 py-2 px-4 text-center text-sm transition-all shadow-sm hover:shadow-lg text-slate-600 hover:text-white hover:bg-slate-800 hover:border-slate-800 focus:text-white focus:bg-slate-800 focus:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              >
                Telegram
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#333333"
                  viewBox="0 0 256 256"
                  className="ms-2 fill-current transition-colors duration-20"
                >
                  <path d="M228.88,26.19a9,9,0,0,0-9.16-1.57L17.06,103.93a14.22,14.22,0,0,0,2.43,27.21L72,141.45V200a15.92,15.92,0,0,0,10,14.83,15.91,15.91,0,0,0,17.51-3.73l25.32-26.26L165,220a15.88,15.88,0,0,0,10.51,4,16.3,16.3,0,0,0,5-.79,15.85,15.85,0,0,0,10.67-11.63L231.77,35A9,9,0,0,0,228.88,26.19Zm-61.14,36L78.15,126.35l-49.6-9.73ZM88,200V152.52l24.79,21.74Zm87.53,8L92.85,135.5l119-85.29Z"></path>
                </svg>
              </a>
              <a
                href="https://scuttle.eeaq.xyz"
                className="flex items-center rounded-md border border-slate-300 py-2 px-4 text-center text-sm transition-all shadow-sm hover:shadow-lg text-slate-600 hover:text-white hover:bg-slate-800 hover:border-slate-800 focus:text-white focus:bg-slate-800 focus:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              >
                Discord
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#333333"
                  viewBox="0 0 256 256"
                  className="ms-2 fill-current transition-colors duration-20"
                >
                  <path d="M104,140a12,12,0,1,1-12-12A12,12,0,0,1,104,140Zm60-12a12,12,0,1,0,12,12A12,12,0,0,0,164,128Zm74.45,64.9-67,29.71a16.17,16.17,0,0,1-21.71-9.1l-8.11-22q-6.72.45-13.63.46t-13.63-.46l-8.11,22a16.18,16.18,0,0,1-21.71,9.1l-67-29.71a15.93,15.93,0,0,1-9.06-18.51L38,58A16.07,16.07,0,0,1,51,46.14l36.06-5.93a16.22,16.22,0,0,1,18.26,11.88l3.26,12.84Q118.11,64,128,64t19.4.93l3.26-12.84a16.21,16.21,0,0,1,18.26-11.88L205,46.14A16.07,16.07,0,0,1,218,58l29.53,116.38A15.93,15.93,0,0,1,238.45,192.9ZM232,178.28,202.47,62s0,0-.08,0L166.33,56a.17.17,0,0,0-.17,0l-2.83,11.14c5,.94,10,2.06,14.83,3.42A8,8,0,0,1,176,86.31a8.09,8.09,0,0,1-2.16-.3A172.25,172.25,0,0,0,128,80a172.25,172.25,0,0,0-45.84,6,8,8,0,1,1-4.32-15.4c4.82-1.36,9.78-2.48,14.82-3.42L89.83,56s0,0-.12,0h0L53.61,61.93a.17.17,0,0,0-.09,0L24,178.33,91,208a.23.23,0,0,0,.22,0L98,189.72a173.2,173.2,0,0,1-20.14-4.32A8,8,0,0,1,82.16,170,171.85,171.85,0,0,0,128,176a171.85,171.85,0,0,0,45.84-6,8,8,0,0,1,4.32,15.41A173.2,173.2,0,0,1,158,189.72L164.75,208a.22.22,0,0,0,.21,0Z"></path>
                </svg>
              </a>
              <a
                href="/telegram"
                className="flex items-center rounded-md border border-slate-300 py-2 px-4 text-center text-sm transition-all shadow-sm hover:shadow-lg text-slate-600 hover:text-white hover:bg-slate-800 hover:border-slate-800 focus:text-white focus:bg-slate-800 focus:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              >
                GitHub
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#333333"
                  viewBox="0 0 256 256"
                  className="ms-2 fill-current transition-colors duration-20"
                >
                  <path d="M208.31,75.68A59.78,59.78,0,0,0,202.93,28,8,8,0,0,0,196,24a59.75,59.75,0,0,0-48,24H124A59.75,59.75,0,0,0,76,24a8,8,0,0,0-6.93,4,59.78,59.78,0,0,0-5.38,47.68A58.14,58.14,0,0,0,56,104v8a56.06,56.06,0,0,0,48.44,55.47A39.8,39.8,0,0,0,96,192v8H72a24,24,0,0,1-24-24A40,40,0,0,0,8,136a8,8,0,0,0,0,16,24,24,0,0,1,24,24,40,40,0,0,0,40,40H96v16a8,8,0,0,0,16,0V192a24,24,0,0,1,48,0v40a8,8,0,0,0,16,0V192a39.8,39.8,0,0,0-8.44-24.53A56.06,56.06,0,0,0,216,112v-8A58.14,58.14,0,0,0,208.31,75.68ZM200,112a40,40,0,0,1-40,40H112a40,40,0,0,1-40-40v-8a41.74,41.74,0,0,1,6.9-22.48A8,8,0,0,0,80,73.83a43.81,43.81,0,0,1,.79-33.58,43.88,43.88,0,0,1,32.32,20.06A8,8,0,0,0,119.82,64h32.35a8,8,0,0,0,6.74-3.69,43.87,43.87,0,0,1,32.32-20.06A43.81,43.81,0,0,1,192,73.83a8.09,8.09,0,0,0,1,7.65A41.72,41.72,0,0,1,200,104Z"></path>
                </svg>
              </a>
              <a
                href="/telegram"
                className="flex items-center rounded-md border border-slate-300 py-2 px-4 text-center text-sm transition-all shadow-sm hover:shadow-lg text-slate-600 hover:text-white hover:bg-slate-800 hover:border-slate-800 focus:text-white focus:bg-slate-800 focus:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              >
                Behance
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#333333"
                  viewBox="0 0 256 256"
                  className="ms-2 fill-current transition-colors duration-20"
                >
                  <path d="M160,80a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H168A8,8,0,0,1,160,80Zm-24,78a42,42,0,0,1-42,42H32a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8H90a38,38,0,0,1,25.65,66A42,42,0,0,1,136,158ZM40,116H90a22,22,0,0,0,0-44H40Zm80,42a26,26,0,0,0-26-26H40v52H94A26,26,0,0,0,120,158Zm128-6a8,8,0,0,1-8,8H169a32,32,0,0,0,56.59,11.2,8,8,0,0,1,12.8,9.61A48,48,0,1,1,248,152Zm-17-8a32,32,0,0,0-62,0Z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
